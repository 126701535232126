<template>
    <v-container fluid>
        <!-- <Breadcrumbs /> -->
        <!-- Knowlarity Creds Input -->
        <v-card class="rounded d-flex flex-row flex-lg-wrap">
            <v-row class="ma-0">
                <v-col>
                    <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
                        <v-card-text style="margin-bottom: 25px">
                            <div align="center" style="margin-bottom: 10px">
                                <h1 style="margin-bottom: 40px">GoDial + 99acres</h1>
                            </div>
                            <div style="padding: 5px 15px; text-align: center">
                                <div style="text-align: left">
                                    <p style="
                              margin-top: 20px;
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                            ">
                                        How to integrate 99acres with GoDial?
                                    </p>
                                    <p>
                                        1. Create a list in GoDial. Goto CRM > New list
                                        <br />
                                        2. Connect your 99acres account with GoDial by selecting
                                        the list where you want to receive leads.
                                        <br />
                                        3. Copy the cURL, and provide them to your
                                        99acres account manager to enable the integration.
                                        <br />
                                        4. Once activated, leads from 99acres will flow into
                                        GoDial automatically in the assigned list.
                                    </p>
                                </div>
                            </div>
                        </v-card-text>
                        <v-footer color="transparent" absolute>
                            <v-flex style="text-align: center !important">
                                <p style="
                            font-size: 12px;
                            opacity: 0.9;
                            margin-bottom: 0px;
                          ">
                                    Feel free to reach out to us at
                                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                                    if you are interested in these features
                                </p>
                            </v-flex>
                        </v-footer>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                            <v-layout row wrap>
                                <v-flex d-flex justify-center class="mt-10" style="padding-top: 22px">Enable 99acres
                                </v-flex>
                                <v-flex class="mt-10" d-flex justify-center>
                                    <v-switch v-model="acres.enabled" color="primary"></v-switch>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-card>
                    <v-card color="transparent" elevation="0"
                        style="margin-top:25px; padding: 15px 50px; text-align: center">
                        <v-card-title primary-title class="justify-center">99acres Credentials</v-card-title>

                        <!-- <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <v-text-field
                v-model="tradeIndia.config.email"
                readonly
                label="Forwarding Email"
                type="text"
                rows="3"
                prepend-inner-icon="email"
              ></v-text-field>
            </v-flex> -->

                        <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
                            <v-autocomplete v-model="acres.selectedList" :disabled="!acres.enabled" class="pt-1"
                                :items="lists" item-text="name" item-value="id" label="Inquiry Lists" return-object
                                prepend-inner-icon="view_list" autocomplete="false" outlined dense />
                        </v-flex>


                        <v-flex xs12 sm12 lg12 offset-sm1 md12 offset-md1 style="margin-bottom: 10px">
                            <v-btn color="primarygrad" dark block @click="setNnacres">Save</v-btn>
                        </v-flex>

                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="acres.enabled && acres.selectedList.id" class="integration-card my-3 pa-2">
            <v-alert color="primary" icon="mdi-alert" theme="dark" prominent class="mt-2">
                Please copy these details and send over to your 99acres account manager via email. Once
                they active
                subscription, lead will start comming to the list selected by you.
            </v-alert>
            <v-card-title primary-title class="integration-title">
                Activate Integration
            </v-card-title>

            <v-card-text class="integration-body">
                <v-row>
                    <v-col>
                        <v-card flat class="integration-details">
                            <!-- Campaign Name -->
                            <!-- <v-card-title class="integration-campaign-name">Test 3</v-card-title>
                            <v-card-subtitle class="integration-subtitle">NINETY_NINE_ACRES</v-card-subtitle> -->
                            <!-- <v-card-subtitle class="integration-subtitle">
                                Please copy these details and send over to your 99acres account manager via email. Once
                                they active
                                subscription, lead will start comming to the list selected by you.
                            </v-card-subtitle> -->

                            <!-- <v-divider class="integration-divider"></v-divider> -->

                            <!-- Endpoint -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Endpoint:</p>
                                    <p class="integration-value">
                                        {{ ENDPOINT }}/externals/99acres?access_token={{ token }}
                                    </p>
                                </v-col>
                            </v-row>

                            <!-- Method -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Method:</p>
                                    <p class="integration-value">POST</p>
                                </v-col>
                            </v-row>

                            <!-- Headers -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Headers:</p>
                                    <p class="integration-value">Content-Type: application/json</p>
                                </v-col>
                            </v-row>

                            <!-- Body -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Body:</p>
                                    <v-textarea
                                        value="{ 'Xml': '<Xml><Qry><QryType>Query</QryType><QryId>mmm_response-1611359</QryId><CmpctLabl><![CDATA[test neha SC buyer]]></CmpctLabl><QryInfo><![CDATA[I am interested in knowing more]]></QryInfo><RvdOn><![CDATA[2014-06-05 15:06:15]]></RvdOn><ProdType></ProdType><ProdId>15003</ProdId><Project>1110</Project><Name><![CDATA[Sudhanshu single click]]></Name><City><![CDATA[jaipur]]></City><Email><![CDATA[single_check@gmail.com]]></Email><Phone><![CDATA[99933473873]]></Phone></Qry></Xml>' }"
                                        readonly outlined dense class="integration-textarea">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                            <!-- cURL -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">cURL:</p>
                                    <v-textarea ref="curlTextarea"
                                        :value="`curl -X POST '${ENDPOINT}/externals/99acres?access_token=${token}' 
         --header 'Content-Type: application/json' 
         --data-raw '{&quot;Xml&quot;:&quot;&lt;XML&gt;&lt;Qry&gt;&lt;QryType&gt;Query&lt;/QryType&gt;&lt;QryId&gt;mmm_response-1611359&lt;/QryId&gt;&lt;CmpctLabl&gt;&lt;![CDATA[test neha SC buyer]]&gt;&lt;/CmpctLabl&gt;&lt;QryInfo&gt;&lt;![CDATA[I am interested in knowing more]]&gt;&lt;/QryInfo&gt;&lt;RvdOn&gt;&lt;![CDATA[2014-06-05 15:06:15]]&gt;&lt;/RvdOn&gt;&lt;ProdType&gt;&lt;/ProdType&gt;&lt;ProdId&gt;15003&lt;/ProdId&gt;&lt;Project&gt;1110&lt;/Project&gt;&lt;Name&gt;&lt;![CDATA[Sudhanshu single click]]&gt;&lt;/Name&gt;&lt;City&gt;&lt;![CDATA[jaipur]]&gt;&lt;/City&gt;&lt;Email&gt;&lt;![CDATA[single_check@gmail.com]]&gt;&lt;/Email&gt;&lt;Phone&gt;&lt;![CDATA[99933473873]]&gt;&lt;/Phone&gt;&lt;/Qry&gt;&lt;/XML&gt;&quot;}'`"
                                        readonly outlined dense block class="integration-textarea">
                                    </v-textarea>

                                </v-col>
                            </v-row>
                            <v-row class="my-0">
                                <v-col>
                                    <v-btn color="primary" @click="copyToClipboard">
                                        Copy to Clipboard
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- Loader -->
        <!-- <v-dialog v-model="openConnect" width="600">
            <v-card light class="py-4 px-2">
                <v-card-text>
                    <h2 class="my-2">
                        Choose List
                    </h2>
                    <v-row>
                        <v-col>
                            <p>
                                <span class="choose-list">
                                    Choose the list you want to connect
                                </span>
                            </p>
                        </v-col>
                        <v-col>
                            <v-select v-model="acres.selectedList" :items="lists" item-text="name" item-value="item"
                                label="Lists" outlined return-object @change="assignList"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="justify-end mx-1">
                        <v-btn color="primary white--text">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

const _ = require("lodash");

export default {
    data() {
        return {
            acres: {
                enabled: false,
                selectedList: {}
            },
            openConnect: false,
            lists: [],
            token: ""
        };
    },

    computed: {
        ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    },
    watch: {},
    async mounted() {
        this.generateToken();
        await this.fetchLists();
        await this.getIntegrationDetails();
    },
    methods: {
        copyToClipboard() {
            const curlCommand = this.$refs.curlTextarea.internalValue; // Get the current value of the textarea
            const message = `Hello,\n\nWe request you to enable the CRM API so that we can get real-time leads in our own custom CRM GoDial.\n\nBelow is the endpoint and example:\n\n`;
            const fullTextToCopy = message + curlCommand; // Prepend the message to the cURL command
            navigator.clipboard.writeText(fullTextToCopy).then(() => {
                this.$swal({ type: "success", text: "Copied to clipboard!" });
            }).catch((err) => {
                console.error("Failed to copy!", err);
            });
        },
        async setNnacres() {
            try {
                // Fetch existing 99acres integration
                let resp = await this.$http.get(
                    `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.$store.getters.companyDetails.id}&filter[where][type]=99acres`
                );
                let respo;

                // Check if an integration already exists
                if (resp.data && resp.data.length && resp.data[0].id) {
                    // Update existing 99acres integration
                    respo = await this.$http.patch(`${this.ENDPOINT}/integrations/${resp.data[0].id}`, {
                        config: {
                            listId: this.acres.selectedList.id,
                        },
                    });
                    console.log("Updated 99acres integration response: ", respo);
                } else {
                    // Create a new 99acres integration
                    respo = await this.$http.post(`${this.ENDPOINT}/integrations`, {
                        type: "99acres",
                        enabled: true,
                        companyId: this.$store.getters.companyDetails.id,
                        config: {
                            listId: this.acres.selectedList.id,
                        },
                    });
                    console.log("New 99acres integration response: ", respo);
                }

                // Check if the response contains a valid ID
                if (respo.data && respo.data.id) {
                    this.$swal({
                        type: "success",
                        text: "Configuration Saved Successfully",
                    });
                } else {
                    this.$swal({
                        type: "error",
                        text: "An error occurred! Please try again.",
                    });
                }
            } catch (error) {
                console.log("99acres integration setting error: ", error);
                this.$swal({
                    type: "error",
                    text: error.response && error.response.data && error.response.data.error
                        ? error.response.data.error.message
                        : "Unknown error occurred.",
                });
            }
        },
        assignList() {
            console.log("selectedList: ", this.acres.selectedList);
        },
        getTeamIds() {
            var teams = [];
            if (this.selectedTeam == "allTeams") {
                teams = _.map(this.teams, "id");
            } else {
                teams.push(this.selectedTeam);
            }

            teams = _.without(teams, "allTeams");

            return teams;
        },
        async fetchLists() {
            try {
                this.lists = [];
                var response = [];

                var teamIds = this.getTeamIds();

                // Teams sections
                response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
                    teams: teamIds,
                });
                this.lists = response.body;
                console.log("this.lists: ", this.lists)

                if (this.lists.length < 1) {
                    this.loader = false;
                    return;
                }
            } catch (e) {
                this.loader = false;
                console.log("error in list fetch", e);
            }
        },
        async getIntegrationDetails() {
            var resp = await this.$http.get(
                `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=99acres`
            );

            console.log("getting 99acres data: ", resp.data[0]);

            if (resp.data && resp.data.length && resp.data[0].id) {
                // console.log("Lists: ", this.lists);
                // console.log("List ID to match: ", resp.data[0].config.listId);
                const chosenList = this.lists.find(item => {
                    // console.log("Type of item.id: ", item.id);
                    // console.log("Type of listId: ", resp.data[0].config.listId);
                    return item.id === resp.data[0].config.listId
                });
                console.log("choosenList: ", chosenList);
                this.acres.selectedList = chosenList;
                this.acres.enabled = true
            }
        },
        generateToken() {
            this.$http
                .get(
                    `${this.ENDPOINT}/externals/token?access_token=${this.$store.getters.user.token}`
                )
                .then((response) => {
                    this.token = response.body.id;
                    console.log("this.token: ", this.token);
                })
                .catch((err) => {
                    this.$swal({ type: "error", text: err.body.error.message });
                });
        },
    },
};
</script>

<style>
.integration-card {
    background-color: #6a1b9a;
    color: white;
    border-radius: 10px;
}

.integration-title {
    /* color: #ff4081; */
    font-size: 24px;
    font-weight: bold;
}

.integration-body {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
}

.integration-details {
    /* border-color: #6a1b9a;
    border-width: 2px;
    padding: 20px; */
}

.integration-campaign-name {
    /* color: #ff4081; */
    font-weight: bold;
    font-size: 20px;
}

.integration-subtitle {
    color: #ff4081;
    font-size: 16px;
    margin: 0;
}

.integration-divider {
    margin: 10px 0;
}

.integration-label {
    font-weight: 600;
    color: black;
    font-size: 14px;
    display: inline;
    margin-block: 0.3rem;
}

.integration-value {
    font-size: 14px;
    color: #000000;
    display: inline;
    margin-inline: 0.5rem;
}

.integration-textarea {
    font-size: 12px;
    color: #000000;
    height: auto;
}

.choose-list {
    font-weight: 500;
    margin-bottom: 0.5rem 0 0.3rem;
}

.warning-msg {
    color: red;
    font-size: 0.7rem;
    display: block;
}

.tutorial {
    width: 100%;
    min-height: 40vh;
    height: auto;
    aspect-ratio: 16/9;
}

.video-container {
    min-width: 20rem;
    width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
}
</style>